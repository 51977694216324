var hasVotedBool = false;

export function getColorForEvent(eventData) {
    if (eventData.type != 1)
      return "#abcdef";
    if (eventData.points == 0)
      return "#ff4560";
    if (eventData.points == 1)
      return "#5b6978";     
    if (eventData.points == 3)
      return "#3dcc5b";
  }

export function setHasVoted(){
  hasVotedBool = true;
}

export function hasVoted(){   return hasVotedBool; }