<template>
    <PushNotificationButton></PushNotificationButton>
    <h2 class="text-center">Jetzt abstimmen</h2>
    Teile uns mit wie du die Lage bei Hertha aktuell siehst. Um so besser deine Stimmung, um so höher sollte die
    Punktzahl
    sein. Let's goo
    <br>
    <vue-slider v-model="sliderValue" :min="1" :max="10" :dotSize="28" :interval="1" :marks="true" :tooltip="'none'"
        class="text-center" :contained="true" />
    <br>
    <h3 class="">Ausgewählt: {{ sliderValue }} <b><i :class="selectedAdjective.color">{{ selectedAdjective.text
                }}</i> {{ selectedAdjective.emoji }}</b></h3>

    <template v-if="!hasVoted">
        <button @click="submitRating" :disabled="isRequesting" class="btn btn-primary custom-button">Abstimmen
            <span v-if="isRequesting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <template v-if="votedFor && votedFor > 0">
            <br><br>Deine letzte Abstimmung: <b>{{ votedFor }}</b>
        </template>
    </template>

    <div v-else id="feedback" class="box opacity-03 success-dialog">
        <b>Danke, deine Stimme wurde gezählt! 🥳</b><br>
        Du kannst jederzeit neu abstimmen. Bei mehrfacher Abstimmung innerhalb von 14 Tagen zählt nur die letzte Stimme.
    <br>
    <div v-if="itsMd">
        <br>
    <b>Es ist Spieltag!</b><br>
    Mache bei der Abstimmung zum Herthaner des Spieltags mit!<br>
    Wo? Auf dem <a href="https://discord.gg/CyzqXTN3Yp" style='font-weight:normal;'>Hertha-Base Discord-Server</a> im Kanal <a href='https://discord.com/channels/882900065071554591/1290273940551499879' style='font-weight:normal;'>#spielerdesspieltags</a><br>
    </div>
   
    </div>
</template>

<script>
import { reactive, toRefs, computed, ref } from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import emitter from '../event-emitter';
import * as utils from '../utils.js';
import { ratingOptions } from '../ratingData';
import PushNotificationButton from './PushNotificationButton.vue';


export default {
    components: {
        VueSlider, PushNotificationButton
    },
    mounted() {},
    setup() {
        let isRequesting = ref(false);
        let hasVoted = ref(false);
        let votedFor = ref(0);
        let itsMd = ref(false);
        votedFor.value = localStorage.getItem('voted-for');

        const sliderData = reactive({
            sliderValue: (votedFor.value && votedFor.value > 0 ? parseInt(votedFor.value) : 5),
            dotSize: 14
        });

        const errorMsg = 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut. Falls der Fehler bestehen bleibt, kontaktiere bitte @flixius auf dem Herthabase Discord-Server, oder schreibe eine E-Mail an kontakt@herthabase.de';
        const userIdisNull = 'Fehler: Deine UserId ist NULL. Bitte die Seite neu laden, nochmal probieren und idealerweise @Flixius auf Discord Bescheid geben, dass dieser Fehler immer noch existiert. :-(';
        //const succMsg = '🥳 Deine Stimme wurde erfolgreich abgegeben. 💙';

        const selectedAdjective = computed(() => {
            return ratingOptions[sliderData.sliderValue - 1];
        });

        const submitRequest = async (token) => {
            try {
                var userId = localStorage.getItem('user-id');
                if (!userId) {
                    toast.error(userIdisNull);
                    return;
                }

                const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/user/submitRating', { rating: sliderData.sliderValue, uId: userId, gToken: token });
                //response received successfully
                if (response.status === 200) {
                    //toast.success(succMsg);
                    hasVoted.value = true;
                    localStorage.setItem('voted-for', sliderData.sliderValue);
                    votedFor = sliderData.sliderValue;
                    utils.setHasVoted();
                    //emitter.emit("ShowResultNow", true);
                } else
                    toast.error(errorMsg);
            }
            catch (error) {
                toast.error(errorMsg);
            }
            finally {
                isRequesting.value = false;
            }
        };
        const submitRating = async () => {
            isRequesting.value = true;

            if (process.env.VUE_APP_ENVIRONMENT == 'DEBUG') {
                await submitRequest(null);
                return;
            }

            window.grecaptcha.ready(async function () {
                const token = await window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITEKEY, { action: 'submit' });     //i receive a token successfully
                await submitRequest(token);
            });
        };


        emitter.on("OnVotingSummaryRequested", _ => {
            itsMd.value = _.ItsMatchday;
        });

        return {
            ...toRefs(sliderData),
            selectedAdjective,
            submitRating,
            isRequesting,
            hasVoted,
            votedFor,
            itsMd
        };
    }
}
</script>