export const ratingOptions = [
    { color: "rating-red", text: "Katastrophal", emoji: "😫" },
    { color: "rating-red", text: "Sehr schlecht", emoji: "🙁" },
    { color: "rating-orange", text: "Schlecht", emoji: "😕" },
    { color: "rating-orange", text: "Naja", emoji: "😐" },
    { color: "rating-yellow", text: "So lala", emoji: "😶" },
    { color: "rating-yellow", text: "Ganz okay", emoji: "🙂" },
    { color: "rating-green", text: "Gut", emoji: "😃" },
    { color: "rating-green", text: "Sehr gut", emoji: "😁" },
    { color: "rating-dark-green", text: "Hervorragend", emoji: "🤩" },
    { color: "rating-dark-green", text: "Legendär", emoji: "🥳" }
];