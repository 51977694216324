<script>
import { toast } from 'vue3-toastify';

export default {
    data() {
        return {
            socket: null, // Store the WebSocket connection 
            connected: false,
            reconnectAttempts: 0, // Track reconnect attempts
            maxReconnectAttempts: 5, // Set a maximum limit for attempts
            reconnectInterval: 3000, // Interval between attempts (milliseconds)
            //bis inkl. 03/04
            players: [
                "Gábor Király",
                "Christian Fiedler",
                "Hendrik Herzog",
                "Dick van Burik",
                "Josip Simunic",
                "Marko Rehmer",
                "Sofian Chahed",
                "Alexander Madlung",
                "Malik Fathi",
                "Tony Sanneh",
                "René Tretschok",
                "Rob Maas",
                "Eyjólfur Sverrisson",
                "Kjetil Rekdal",
                "Sixten Veit",
                "Pál Dárdai",
                "Andreas Schmidt",
                "Zecke Neuendorf",
                "Niko Kovac",
                "Thorben Marx",
                "Sebastian Deisler",
                "Luizão",
                "Nando Rafael",
                "Joël Tchami",
                "Bart Goor",
                "Ante Covic",
                "Michael Hartmann",
                "Dariusz Wosz",
                "Andreas Thom",
                "Ali Daei",
                "Michael Preetz",
                "Artur Wichniarek",
                "Alex Alves",
                "Fredi Bobic",
                "Nando Rafael",
                "Arne Friedrich",//ab hier bonus
                "Yıldıray Bastürk",
                "Rune Jarstein",
                "Kevin-Prince Boateng"]
        };
    },
    mounted() {
        this.connect(); // Attempt connection on mount
    },
    render() { return ""; },
    beforeUnmount() {
        // Clean up when the component unmounts
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    },
    methods: {
        toastRandomPlayer(num) {
            var rIx = Math.floor(Math.random() * this.players.length);
            var plr = this.players[rIx];
            var txt = `<b>${plr}</b> hat die Stimmung gerade mit <b>${num}</b> bewertet`;
            console.log(txt);
            toast(txt, {
                "theme": "auto",
                "type": "default",
                "autoClose": 3500,
                "position": "top-right",
                "transition": "flip",
                "dangerouslyHTMLString": true
            });
        },
        connect() {
            const socket = new WebSocket(process.env.VUE_APP_WS_URL);
            socket.addEventListener('open', () => {
                console.log('WebSocket connected');
                this.connected = true;
            });

            socket.addEventListener('message', (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'onNewScoreSubmitted' && data.score && data.score > 0)
                    this.toastRandomPlayer(data.score);
            });

            socket.addEventListener('error', (error) => {
                console.error('WebSocket Error:', error);
            });

            socket.addEventListener('close', () => {
                console.log('WebSocket disconnected');
                this.connected = false;
                this.reconnect();
                // Consider adding reconnect logic here 
            });

            this.socket = socket;
        },
        reconnect() {
            if (this.reconnectAttempts < this.maxReconnectAttempts) {
                this.reconnectAttempts++;
                console.log('ws reconnecting attempt:', this.reconnectAttempts);

                setTimeout(() => {
                    this.connect();
                }, this.reconnectInterval);
            } else
                console.error('Max. ws reconnect attempts reached', this.maxReconnectAttempts);
        }
    }
};
</script>
