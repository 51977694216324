<template>
    <div class="welcome-header">
      <span class="h1" ref="typingTarget"></span>
      <p>Wie geht es uns denn so rund um Hertha BSC?</p>
    </div>
  </template>
  
  <script>
  import Typed from 'typed.js';
  import { ref, onMounted } from 'vue';
  
  export default {
    setup() {
      const typingTarget = ref(null);
  
      onMounted(() => {
        new Typed(typingTarget.value, {
          strings: ["Berliner...", "Brandenburger...", "Herthafans,"],
          typeSpeed: 100,
          backSpeed: 60,
          loop: false
        });
      });
  
      return { typingTarget };
    }
  }
  </script>
  
  <style scoped> 
  /* Any specific styles for the WelcomeHeader can go here */
  </style>
  