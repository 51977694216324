<template>
  <router-view />
  <websocket-handling></websocket-handling>
</template>
<script>
import WebsocketHandling from './components/WebsocketHandling';
export default {
  components: {
    WebsocketHandling
  }
}
</script>
