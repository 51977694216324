<template>
    <template></template>
</template>

<script>
const vuid = require('vuid').default;

export default {
    mounted() {
        this.userID = this.getUserID();
    },
    data() {
        return {
            userID: null,
        };
    },
    methods: {
        getUserID() {
            const storedUserID = localStorage.getItem('user-id');
            const expiry = localStorage.getItem('expiry-date');
            
            if (!storedUserID || !expiry || new Date() > new Date(expiry)) {
                const newUserID = vuid();
                localStorage.setItem('user-id', newUserID);
                localStorage.setItem('expiry-date', this.getExpiryDate());
                return newUserID;
            } else {
                return storedUserID;
            }
        },
        getExpiryDate() {
            const now = new Date();
            now.setDate(now.getDate() + 14); // 14 days from now
            return now;
        }
    }
}
</script>
