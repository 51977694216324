<template>
    <div class="row mt-4 p-4">
        <div class="col-12 mb-4">
            <h2>Test</h2>
            <br>
            &bullet; Wird dir hier unter diesem Text ein Button angezeigt?<br>
            &bullet; Bitte Gerät & Browser nennen<br>
            &bullet; Funktioniert der Button, wenn du ihn anklickst?
            <br>
            <br>
            <button id="install-button"  class="btn btn-primary custom-button" @click="a2hsInstall" v-if="showInstallButton">Install App Button</button>
        </div>
    </div>
</template>

<script>
import { toast } from 'vue3-toastify';

export default {
    methods: {
        a2hsInstall() {
            if(this.a2hsPrompt == null){
                toast.error("prompt is null");
                return;
            }
            this.a2hsPrompt.prompt();
            this.a2hsPrompt.userChoice.then((choiceResult) => {
                //alert("user choice outcome: " + choiceResult.outcome);
                if (choiceResult.outcome === 'accepted') {
                    toast.success("nutzerauswahl: akzeptiert");
                    console.log('User accepted the A2HS prompt');
                } else {
                    toast.success("nutzerauswahl: abgebrochen");
                    console.log('User dismissed the A2HS prompt');
                }
                // Reset the deferredPrompt variable
                this.a2hsPrompt = null;
                this.showInstallButton = false;
            });
        },
        a2hsInit() {
            window.addEventListener('beforeinstallprompt', (event) => {
                toast.success("eventlistener hinzugefügt");
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                event.preventDefault();
                // Stash the event so it can be triggered later
                this.a2hsPrompt = event;
                this.showInstallButton = true;
            });
        }
    },
    //watch: {selectedEvent: async function (news) {} },
    data: function () {
        return {
            showInstallButton: false,
            a2hsPrompt: null,
        }
    },
    mounted: function () {
        this.a2hsInit();
    }
};
</script>