<script setup>
import { GaugeMeter } from "vue3-gauge-chart";
import { ref, defineProps, watch, computed } from 'vue';
import { ratingOptions } from '../ratingData';
import ShowTrend from './ShowTrend.vue';

let showRating = ref(false);
let showVotingDetails = ref(false);

const props = defineProps({
    rating: {
        type: Number,  // Specify that it expects a number
        default: 0      // Optional default value
    },
    resultModel: {
        type: Object,
        default: null
    }
});

watch(() => props.rating, (newRating, oldRating) => {
    //console.log("watch trigger: new " + newRating + " old " + oldRating);
    if (newRating === oldRating)
        return;
    //console.log("force re-render new value: " + newRating);
    updateRating(newRating);
});

const options = {
    areaWidth: 400,
    hasNeedle: true,
    outerNeedle: false,
    needleColor: "white",
    needleStartValue: 0,
    needleUpdateSpeed: 1500,
    arcColors: ["rgb(234, 57, 67)", "rgb(234, 140, 0)", "rgb(243, 212, 47)", "rgb(147, 217, 0)", "rgb(22, 199, 132)"],
    arcDelimiters: [20, 40, 60, 80],
    rangeLabel: ["1", "10"],
    centralLabel: "",
    needleValue: ref(0)
};

function updateRating(ratingVal) {
    setTimeout(function () {
        if (ratingVal > 0) {
            options.needleValue.value = ratingVal * 10;

            setTimeout(function () {
                showRating.value = true;
                showVotingDetails.value = true;
            }, options.needleUpdateSpeed);
        }
    }, 100);
}

updateRating(props.rating);

const ratingStringRepresentation = computed(() => {
    const roundedRating = Math.round(props.rating);
    var representation = ratingOptions[roundedRating - 1];
    const { color, text, emoji } = representation;
    return `<b class="${color}">${text} ${emoji}</b>`;
});
</script>

<template>
    <div class="text-center">
        <GaugeMeter :options="options" />
        <div style="margin-top:-60px;" class="text-center">
            <h3 class="text-white">
                <template v-if="showRating">
                    <div class="fa-beat" style="--fa-animation-duration: 2s;">{{ props.rating.toFixed(2) }}</div>
                    <div v-html="ratingStringRepresentation"></div>
                </template>
                <template v-else>
                    ???
                </template>
            </h3>
            <div v-if="showVotingDetails && resultModel?.Votes > 0" class="text-left">
                <span class="h4">14 Tage Schnitt</span><br>
                <b>{{ resultModel.Votes }} Stimmen</b><br>
                <!--Gezählte Stimmen: <b class="h4">{{ resultModel.Votes }}</b>-->
                <div v-if="showVotingDetails && resultModel.Trend != null">
                    <show-trend :trend="resultModel.Trend"></show-trend>
                </div>
            </div>
        </div>
    </div>
</template>