import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; 

import { library } from '@fortawesome/fontawesome-svg-core'; 
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'; 
import { faBell } from '@fortawesome/free-solid-svg-icons'; 
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'; 
import { faArrowTrendDown } from '@fortawesome/free-solid-svg-icons'; 
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'; 
import VueTypedJs from 'vue-typed-js'
import VueApexCharts from "vue3-apexcharts";
import './assets/custom.css'; 
import './registerServiceWorker';

library.add(faChevronLeft); 
library.add(faArrowTrendUp); 
library.add(faArrowTrendDown); 
library.add(faCircleInfo); 
library.add(faBell); 



import Vue3Toastify from 'vue3-toastify'; 
import 'vue3-toastify/dist/index.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App); // Create app instance

app.use(router); 
//app.config.globalProperties.emitter = emitter;
app.use(VueApexCharts);
app.use(VueSweetalert2);
app.use(Vue3Toastify, {
    autoClose: 8000,
    theme:"dark",
    "position": "top-right", 
  }); 
app.component('font-awesome-icon', FontAwesomeIcon); // Register globally
app.component('vue-typed-js', VueTypedJs);
app.mount('#app');
