<!-- navbar-light bg-light-->

<template>
    <user-management></user-management>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <span class="navbar-brand">
        <a href="/"><img src="/hb-logo.png" style="height:40px; margin-right:12px;" class=""/></a> Stimmung 
        🥳</span>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="nav-link" href="https://herthabase.de">Zu Hertha BASE</a>
          <a class="nav-link" href="https://spenden.herthabase.de">Spenden</a>
        </div>
      </div>
    </div>
  </nav>

    <div class="container-fluid mt-4">
      <main>
        <div class="row">
          <div class="col-xl-8 offset-xl-2 col-12">
            <welcome-header />
          </div>
  
          <div class="col-xl-4 offset-xl-2 col-md-6 mt-4">
            <voting-form></voting-form>
          </div>
  
          <div class="col-xl-4 col-md-6 mt-4">
            <show-barometer></show-barometer>
          </div>
  

          <div class="col-xl-8 offset-xl-2 col-12 mt-5">
            <apex-chart></apex-chart>
          </div>

          <div class="col-12 text-center" style="margin-top:25px; margin-bottom:50px;">
            <a href='https://herthabase.de'><img src="./assets/hb-logo.png" style="max-width: 150px;" /></a>
          </div>
        </div>
      </main>
    </div>
    <footer class="footer fixed-bottom bg-light mt-4">
      <div class="container-fluid text-center">
        <b class="text-muted">Oh yeah, it's a <b><a href="https://lixius.de" class="text-white">Lixius</a></b> product
          😎</b>
      </div>
    </footer>
  </template>
  
  <script>
  import WelcomeHeader from './components/WelcomeHeader';
  import ShowBarometer from './components/ShowBarometer';
  import VotingForm from './components/VotingForm';
  import UserManagement from './components/UserManagement';
  import ApexChart from './components/ApexChart';
  
  export default {
    components: {
      WelcomeHeader,
      ShowBarometer,
      VotingForm,
      UserManagement,
      ApexChart
    },
    setup(){},
    mounted(){}
  }
  </script>
  