// src/router.js 
import { createRouter, createWebHistory } from 'vue-router';
import MainView from './MainView.vue';
import ManageEvents from './admin/ManageEvents.vue';
import A2Hs from './A2HsView.vue';

const routes = [
    { path: '/', component: MainView },
    { path: '/admin', component: ManageEvents },
    { path: '/test', component: A2Hs }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;