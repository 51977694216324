<template>
  <button v-if="!chartLoaded" @click="fetchData" :disabled="isfetchingData" class="btn btn-primary custom-button">📈
    Stimmung im Saisonverlauf ansehen
    <span v-if="isfetchingData" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>

  <template v-if="showChart && allSeasonData && allSeasonData.length > 0">
    <div class="btn-group mb-4">
      <a v-for="seasonData in allSeasonData" :key="seasonData.season" href="#" class="btn btn-primary custom-button"
        :class="{ active: seasonData.season === selectedSeason.season }" @click.prevent="changeSeason(seasonData)">
        {{ seasonData.season }}
      </a>
    </div>
    <div class="mb-4" v-if="selectedSeason">
      <b>Statistiken zur {{ selectedSeason.season }}</b><br>
      Hoch/Tief: <b class="text-success">{{ selectedSeason.high.avgScore }}</b> am {{
    formatDate(selectedSeason.high.date) }} / <b class="text-danger">{{ selectedSeason.low.avgScore }}</b> am {{
    formatDate(selectedSeason.low.date) }}<br>
      Meiste Stimmen: <b>{{ selectedSeason.mostVotes.votes }}</b><br>
      Median: {{ selectedSeason.median }}<br>
      Durchschnitt: {{ selectedSeason.avgScore }}
    </div>
  </template>

  <apexchart ref="apexChart" v-if="showChart" type="line" :options="computedChartOptions" :series="computedSeries"
    style="color:black!important;"></apexchart>
  <span v-if="showChart" class="d-md-none">
    <strong>Für eine bessere Darstellung, bitte das Gerät drehen. 🙂</strong>
  </span>
  <div v-if="showChart && selectedSeason && selectedSeason.description">{{ selectedSeason.description }}</div>
</template>
<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import * as utils from '../utils.js';
import ApexCharts from 'apexcharts';


const dtFormat = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
export default {
  methods: {
    formatDate(dt) {
      return dtFormat.format(new Date(dt));
    },
    changeSeason(seasonData) {
      this.selectedSeason = seasonData;

      this.$nextTick(() => {
        const chartID = 'linechart-2'; // Replace with the ID of your chart

        ApexCharts.exec(chartID, 'updateSeries', [{ data: this.selectedSeason.entries }], false);

        ApexCharts.exec(chartID, 'updateOptions', {
          title: { text: 'Stimmungsverlauf ' + this.selectedSeason.season },
          annotations: { points: this.createPointAnnotations(this.selectedSeason.entries) }
        }, true, true);
      });
    },
    createPointAnnotations(data) {
      var events = data.filter(item => item.evnt !== null);
      var pas = [];

      events.forEach(item => {
        var eventData = JSON.parse(item.evnt);
        var labelColor = utils.getColorForEvent(eventData);

        var pointAnnotation = {
          x: new Date(item.x).getTime(),
          y: item.y,
          marker: { strokeWidth: 0, radius: 0 },
          label: {
            borderColor: labelColor,
            offsetY: -10,
            style: {
              color: '#fff',
              background: labelColor,
              //fontSize: 16
            },
            text: (eventData.type == 1 ? eventData.text : null),
          },
          image: {
            path: (eventData.type == 1 ? "/chart-imgs/" + eventData.image : "/chart-imgs/thunder.png"),
            //width: 30,
            //height: 30
          }
        };

        if (eventData.type != 1)
          pointAnnotation.label = {};//don't show label when no matchday event
        pas.push(pointAnnotation);
      });

      return pas;
    },
    async fetchData() {
      this.isfetchingData = true;
      try {
        const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/user/getChartData', null);
        let seasonToShow = response.data[response.data.length - 1];

        this.allSeasonData = response.data;
        this.selectedSeason = seasonToShow;
        this.chartTitle = seasonToShow.season;


        this.pointAnnotations = this.createPointAnnotations(seasonToShow.entries);
        this.chartSeriesData = seasonToShow.entries;

        this.showChart = true;
        this.chartLoaded = true;
      } catch (err) {
        console.log(err);
        toast.error("Fehler: Chart konnte nicht geladen werden. :(");
      } finally {
        this.isfetchingData = false;
      }
    }
  },
  computed: {
    computedChartOptions() {
      return {
        chart: {
          height: 350,
          width: 800,
          type: 'line',
          id: 'linechart-2',
          zoom: { enabled: false },
          toolbar: { show: false }
        },
        annotations: {
          points: this.pointAnnotations,
        },
        theme: {
          mode: 'dark',
          palette: 'palette1'

        },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        grid: { padding: { right: 20, left: 10 } },
        title: {
          text: 'Stimmungsverlauf ' + this.chartTitle,
          offsetY: 10,
          align: 'center',
          style: { fontSize: '18px', fontFamily: 'Arial' }
        },
        xaxis: {
          type: 'datetime',
          crosshairs: { show: false },
          labels: {
            format: 'dd.MM.',
            datetimeFormatter: { month: "dd.MM.", day: 'dd.MM.' }
          }
        },
        tooltip: {
          x: {
            show: false,
            formatter: function (value) {
              return dtFormat.format(new Date(value));
            }
          },
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            var data = w.config.series[seriesIndex].data[dataPointIndex];
            var tooltipHeader = "<small>" + dtFormat.format(new Date(data.x)) + "</small><br>Stimmung: <b>" + data.y + "</b> (" + data.votes + " Stimmen)";
            var eventDetails = "";
            if (data.evnt !== null) {
              tooltipHeader += "<br>";
              var parsedEvent = JSON.parse(data.evnt);
              //type 1 => spiel
              if (parsedEvent.type == 1) {
                eventDetails = "<br>" + parsedEvent.md + "<br><img src='/chart-imgs/" + parsedEvent.image + "' style='width:20px;height:20px;'/> <span style='padding-left:5px;padding-right:5px;color:white;background-color:" + utils.getColorForEvent(parsedEvent) + ";'>" + parsedEvent.text + "</span>";
              }
              else
                eventDetails = "<span><br><b>Ereignis</b><br>" + parsedEvent.text + "</span>";
            }
            return "<div style='padding:5px;font-family:Arial;'>" + tooltipHeader + eventDetails + "</div>";
          }
        }
      };
    },
    computedSeries() {
      return [
        {
          data: this.chartSeriesData,
          name: "Stimmung",
        },
      ];
    }
  },
  data: function () {
    return {
      chartLoaded: false,
      showChart: false,
      isfetchingData: false,
      chartSeriesData: [],
      pointAnnotations: [],
      allSeasonData: [],
      selectedSeason: null,
      chartTitle: null
    };
  },
};
</script>
