<template>
    <div class="text-center">
        <h2 class="text-center">Stimmungsbarometer</h2>
        <barometer-v2 :rating="data.VotingResult" :resultModel="data"></barometer-v2>
        <div v-if="!data.Votes">
            <button class="btn btn-primary custom-button bg-warning disabled-button"> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lade Daten</button>
        </div>
        <div v-if="data.Votes && data.Votes == 0">
            In den letzten 30 Tagen wurde keine Stimme abgegeben.
        </div>
        <div v-if="data.Votes && data.Votes > 0 && !data.ShowVotingResult">
            <button @click="onShowVotingResult" class="btn btn-primary custom-button">Ergebnis anzeigen</button>
        </div>
    </div>
</template>

<script>
//import { CircleProgressBar } from 'circle-progress.vue';
import BarometerV2 from './BarometerV2';
import axios from 'axios';
import { onMounted, reactive } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import emitter from '../event-emitter';
import * as utils from '../utils.js';

export default {
    props: [],
    components: {
        BarometerV2
    },
    mounted() {

    },
    setup() {
        const data = reactive({
            AvgScore: null,
            Votes: null,
            ShowVotingResult: false,
            VotingResult: 0,
            Trend: null
        });

        emitter.on("ShowResultNow", _ => {
            console.log(_);
            data.VotingResult = data.AvgScore;
            data.ShowVotingResult = true;
            setTimeout(function () {
                var fbDoc = document.getElementById('feedback');
                fbDoc.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        });

        const onShowVotingResult = async () => {

            if(utils.hasVoted()){
                data.VotingResult = data.AvgScore;
                data.ShowVotingResult = true;
                return;
            }

            Swal.fire({
                title: "",
                icon: "question",
                html: `
  <b>Bist du dir sicher? 🤔</b><br>
  Bevor du das Ergebnis siehst, kurz aufgepasst: Der <b><i>Anker-Effekt</i></b> besagt, dass unsere erste Einschätzung stark von der ersten Information beeinflusst wird, die wir bekommen. Das Ergebnis könnte also deine Meinung zu deiner eigenen Abstimmung unbewusst beeinflussen. 🤯<br><br>
  Soll ich es dir trotzdem anzeigen?
  `,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: `Ja`,
                cancelButtonText: `Nein`,
            }).then((result) => {
                if (!result.isConfirmed)
                    return;
                data.VotingResult = data.AvgScore;
                data.ShowVotingResult = true;
            });
        };
        
        onMounted(async () => {
            try {
                const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/user/getVotingSummay');
                // Update data properties
                data.AvgScore = response.data.AvgScore;
                data.Votes = response.data.Votes;
                data.Trend = response.data.Trend;
                emitter.emit("OnVotingSummaryRequested", response.data);
            } catch (error) {
                console.error("Error fetching value:", error);
            }
        });
        return {
            data,
            onShowVotingResult
        };
    }
}
</script>