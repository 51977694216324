<template>
    <div class="row mt-4 p-4">
        <div class="col-12 mb-4">
            <h2>Eventverwaltung</h2>
            <button id="install-button" @click="a2hsInstall" v-if="showInstallButton">Install App</button>
        </div>
        <div class="col-12" v-if="!dataLoaded">Lade Daten...</div>
        <div class="col-12" v-else>
            Event auswählen...<br>
            <select v-model="selectedEvent">
                <option v-for="event in events" :key="event.id" :value="event">
                    {{ formatDate(event.date) }}
                    <template v-if="event.evnt.md"> || {{ event.evnt.md }}</template>
                </option>
            </select>
            <br>
            <br>
            <template v-if="selectedEvent && selectedEvent.evnt">
                <b>Ausgewähltes Event</b><br><br>
                <img v-if="selectedEvent.evnt.image" :src="'/chart-imgs/' + selectedEvent.evnt.image"
                    style="width:50px;height:50px;" />
                <br>
                <b>Spieltag</b><br>
                <input type="text" v-model="selectedEvent.evnt.md" placeholder="Spieltag"><br><br>
                <b>Endstand</b><br>
                <input type="text" v-model="selectedEvent.evnt.text" placeholder="Egebnis eintragen">
                <br>
                <small>(Format: Heimtore:Auswärtstore)</small>
                <br><br><b>Resultat</b><br>
                <select v-model="selectedEvent.evnt.points">
                    <option v-for="p in points" :key="p.points" :value="p.points">
                        {{ p.text }}
                    </option>
                </select>
                <br><br>
                <input type="text" v-model="masterpasswort" placeholder="Masterpasswort" />
                <br>
                <br>
                <button @click="submitEvent" :disabled="isSubmitting" class="btn btn-primary custom-button">Absenden
                    <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';

export default {
    methods: {
        async submitEvent() {
            this.isSubmitting = true;
            try {
                if (!this.masterpasswort) {
                    toast.error("Ohne Passwort kommst du hier nicht weiter");
                    return;
                }
                this.selectedEvent.pw = this.masterpasswort;
                const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/submitEvent', this.selectedEvent);
                if (response.status === 200)
                    toast.success("Event erfolgreich eingetragen");
                else
                    toast.error("Es ist ein Fehler aufgetreten...");
            } catch (error) {
                if (error.response && error.response.status === 403)
                    toast.error("Du kommst hier net rein! Falsches Passwort :D");
                else
                    toast.error("Es ist ein Fehler aufgetreten " + error);
            } finally {
                this.isSubmitting = false;
            }
        },
        formatDate(dateString) {
            const dtFormat = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
            return dtFormat.format(new Date(dateString));
        },
        a2hsInstall() {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult) => {
                alert("user choice outcome: " + choiceResult.outcome);
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                // Reset the deferredPrompt variable
                this.deferredPrompt = null;
            });
        },
        a2hs() {
            window.addEventListener('beforeinstallprompt', (event) => {
                //alert("addEventListener");
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                event.preventDefault();
                // Stash the event so it can be triggered later
                this.deferredPrompt = event;
                this.showInstallButton = true;
            });
        }
    },
    //watch: {selectedEvent: async function (news) {} },
    data: function () {
        return {
            deferredPrompt: null,
            showInstallButton: false,
            dataLoaded: false,
            isSubmitting: false,
            events: [],
            selectedEvent: null,
            masterpasswort: null,
            points: [
                { points: -1, text: "Bitte auswählen..." },
                { points: 3, text: "Hertha Sieg" },
                { points: 1, text: "Hertha Unentschieden" },
                { points: 0, text: "Hertha Niederlage" }
            ]
        }
    },
    mounted: async function () {
        const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/admin/getEvents', null);
        this.events = response.data;
        const defaultEvent = this.events
    .filter(event => event.evnt.text === null) // Filter events where text is null
    .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort ascending by date
    .find(() => true); // Take the first element (oldest event with text == null)

        this.selectedEvent = (defaultEvent ? defaultEvent : this.events[this.events.length - 1]);
        this.dataLoaded = true;

        this.a2hs();

    }
};
</script>